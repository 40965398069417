import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { main, muiTheme } from "@iventis/styles";
import { App } from "./app";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={muiTheme(main, "light", "ltr")}>
            <App />
        </ThemeProvider>
    </React.StrictMode>
);
