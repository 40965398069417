/* eslint-disable no-console */
/** Get all query params out of url to config the map */
export function getMapParams() {
    const exportMapId = getQueryParams("mapId");
    const boundsNELng = getQueryParams("boundsNELng");
    const boundsNELat = getQueryParams("boundsNELat");
    const boundsSWLng = getQueryParams("boundsSWLng");
    const boundsSWLat = getQueryParams("boundsSWLat");
    const backgroundId = getQueryParams("backgroundId");
    const modelsConfigId = getQueryParams("modelsConfigId");
    const layerIdToSearch = getQueryParams("layerIdToSearch");
    const keyImage = getQueryParams("key");

    if (exportMapId == null) {
        throw new Error("Missing mapId query parameter");
    }

    if (boundsNELng == null || boundsNELat == null || boundsSWLng == null || boundsSWLat == null) {
        throw new Error("Missing bounds query parameter, needs boundsNELng, boundsNELat, boundsSWLng, boundsSWLat");
    }

    if (backgroundId == null) {
        throw new Error("Missing backgroundId query parameter");
    }

    if (modelsConfigId == null) {
        throw new Error("Missing modelsConfigId query parameter");
    }

    const bounds: [number, number, number, number] = [
        Number.parseFloat(boundsNELng),
        Number.parseFloat(boundsNELat),
        Number.parseFloat(boundsSWLng),
        Number.parseFloat(boundsSWLat),
    ];

    if (bounds.some((coord) => Number.isNaN(coord) || coord === null)) {
        throw new Error("Bounds query parameters are not numbers");
    }

    return { exportMapId, bounds, backgroundId, modelsConfigId, layerIdsToSearch: layerIdToSearch == null ? [] : [layerIdToSearch], keyImage };
}

function getQueryParams(key: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
}
