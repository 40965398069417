import { Geometry, Point, Feature, LineString, Polygon, FeatureCollection } from "geojson";
import { DataFieldValueV2 } from "@iventis/domain-model/model/dataFieldValueV2";
import { IventisExportedMap } from "@iventis/domain-model/model/iventisExportedMap";
import {
    MapImportLayer,
    LineMapImportLayer,
    PointMapImportLayer,
    AreaMapImportLayer,
    IconMapImportLayer,
    ModelMapImportLayer,
    LineModelMapImportLayer,
    MapObjectProperties,
} from "../types/export-types";

/** Create geojson for each layer in an exported map */
export function generateGeoJson(map: IventisExportedMap) {
    const parsedFeatures: FeatureCollection<Geometry, MapObjectProperties> = { type: "FeatureCollection", features: [] };
    map.layers.forEach((layer) => {
        // Get map object name datafield so we can use it to search features later
        const mapObjectNameDataField = layer.dataFields.find((df) => df.systemDataFieldName === "MapObjectName");

        if (mapObjectNameDataField == null) {
            throw new Error(`No data field found for map object name`);
        }

        const features = layerMapObjectsToFeature(layer as MapImportLayer, mapObjectNameDataField.id);
        parsedFeatures.features.push(...features);
    });
    return parsedFeatures;
}

export function layerMapObjectsToFeature(layer: MapImportLayer, mapObjectNameDataFieldId: string): Feature<Geometry, MapObjectProperties>[] {
    switch (layer.styleType) {
        case "Area":
            return createAreaFeatures(layer, mapObjectNameDataFieldId);
        case "Line":
            return createLineFeatures(layer, mapObjectNameDataFieldId);
        case "LineModel":
            return createLineModelFeatures(layer, mapObjectNameDataFieldId);
        case "Point":
            return createPointFeatures(layer, mapObjectNameDataFieldId);
        case "Icon":
            return createIconFeatures(layer, mapObjectNameDataFieldId);
        case "Model":
            return createModelFeatures(layer, mapObjectNameDataFieldId);
        default:
            throw new Error(`Unknown style type`);
    }
}

function createAreaFeatures(layer: AreaMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id);
        const feature = createAreaFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createAreaFeature(coords: number[][][], properties: MapObjectProperties): Feature<Polygon, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: coords,
        },
        properties,
    };
}

function createLineFeatures(layer: LineMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id);
        const feature = createLineFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createLineModelFeatures(layer: LineModelMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id);
        // Need level so the features will show in deckgl engine
        dataFieldValues.level = 0;
        const feature = createLineFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createLineFeature(coords: number[][], properties: MapObjectProperties): Feature<LineString, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coords,
        },
        properties,
    };
}

function createPointFeatures(layer: PointMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id);
        const feature = createPointFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createIconFeatures(layer: IconMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id);
        const feature = createPointFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createModelFeatures(layer: ModelMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id);
        // Need level and rotation for deckgl engine
        dataFieldValues.level = 0;
        dataFieldValues.rotation = mapObject.rotation;
        const feature = createPointFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createPointFeature(coords: [number, number], properties: MapObjectProperties): Feature<Point, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: coords,
        },
        properties,
    };
}

/**
 * Given a map object, create a feature properties for it
 */
function createFeatureProperties(layerId: string, layerName: string, mapObjectNameDataFieldId: string, dataFieldValues: DataFieldValueV2[], objectId: string) {
    const parsedDataFieldValues: MapObjectProperties = {};
    // Assign basic properties
    parsedDataFieldValues.layerId = layerId;
    parsedDataFieldValues.layerName = layerName;
    parsedDataFieldValues.id = objectId;
    // Assign data field values to properties
    dataFieldValues.forEach((dfv) => {
        if (dfv.dataFieldId === mapObjectNameDataFieldId) {
            parsedDataFieldValues.name = getNoneNullDataFieldValue(dfv);
        } else {
            parsedDataFieldValues[dfv.dataFieldId] = getNoneNullDataFieldValue(dfv);
        }
    });
    return parsedDataFieldValues;
}

/** Get the non value value from a DataFieldValueV2 type */
function getNoneNullDataFieldValue(dfv: DataFieldValueV2): string | number | null {
    let val: string | number | null = null;
    Object.entries(dfv).forEach(([key, value]) => {
        if (val == null && key !== "dataFieldId" && value !== null) {
            val = value;
        }
    });
    return val;
}
