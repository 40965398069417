import { SearchBarComponent } from "@iventis/search";
import { InteractiveElement, media, styled } from "@iventis/styles";
import "@iventis/styles/src/font-awesome";
import React, { useState, useEffect } from "react";
import { Feature } from "geojson";
import { useDebounce } from "@iventis/utilities";

export const PublicMapSearchComponent = ({ features, featureClicked }: { features: Feature[]; featureClicked: (feature: Feature) => void }) => {
    const [results, setResults] = useState<{ features: Feature[]; noResults: false } | { features: []; noResults: true }>({ noResults: false, features: [] });
    const { debouncedValue, setValue, value } = useDebounce("", 500);

    /** When a user searches */
    const handleSearch = (searchValue: string) => {
        setValue(searchValue);
        setResults({ noResults: false, features: [] });
    };

    /** When a user clicks on a result */
    const handleFeatureClicked = (feature: Feature) => {
        setValue("");
        setResults({ noResults: false, features: [] });
        featureClicked(feature);
    };

    useEffect(() => {
        // Don't search results if there are less than 2 characters
        if (debouncedValue.length < 2) {
            return;
        }
        const foundFeatures: Feature[] = [];

        // Search for features that contain the search value
        for (let i = 0; i < features.length; i += 1) {
            const featureName = features[i].properties?.name.toLowerCase();
            if (featureName.includes(debouncedValue.toLowerCase())) {
                foundFeatures.push(features[i]);
            }
            if (foundFeatures.length >= 3) {
                break;
            }
        }
        // Set results
        if (foundFeatures.length === 0) {
            setResults({ features: [], noResults: true });
        } else {
            setResults({ features: foundFeatures, noResults: false });
        }
    }, [debouncedValue]);

    return (
        <SearchBarContainer>
            <SearchBarComponent placeHolderText="Search" value={value} onValueChange={handleSearch} />
            {results.features.length > 0 && (
                <ResultContainer>
                    {results.features.map((result) => (
                        <InteractiveElement key={result.properties.id} className="search-result" onClick={() => handleFeatureClicked(result)}>
                            {result.properties.name}
                        </InteractiveElement>
                    ))}
                </ResultContainer>
            )}
            {results.noResults && (
                <ResultContainer className="search-result">
                    <InteractiveElement className="search-result no-results">No Results</InteractiveElement>
                </ResultContainer>
            )}
        </SearchBarContainer>
    );
};

const SearchBarContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    width: calc(90% - 20px);

    .MuiTextField-root {
        width: 90%;
    }

    ${media.small} {
        width: 300px;

        .MuiTextField-root {
            width: 300px;
        }
    }
`;

const ResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${(props) => props.theme.typographyColors.blank};

    .search-result {
        font-weight: 600;
        height: 50px;
        padding: 10px;
        font-size: 16px;
        padding: 5px;
        border-radius: 5px;
        background-color: ${(props) => props.theme.typographyColors.blank};
        cursor: pointer;
        border-radius: 10px;
    }

    .search-result:hover {
        background-color: ${(props) => props.theme.shades.four};
    }

    .no-results {
        cursor: default;
    }

    .no-results:hover {
        background-color: ${(props) => props.theme.typographyColors.blank};
    }
`;
