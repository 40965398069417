import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InteractiveElement, borderRadius, media, styled } from "@iventis/styles";
import React from "react";

export const ToggleKeyComponent = ({ showKey, setShowKey }) => (
    <ToggleKeyButton showKey={showKey} onClick={() => setShowKey(!showKey)}>
        <FontAwesomeIcon icon={["far", showKey ? "xmark" : "square-list"]} size="lg" className="icon" />
    </ToggleKeyButton>
);

const ToggleKeyButton = styled(InteractiveElement)<{ showKey: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.small} {
        display: none;
    }

    // Specify the size of the button as the icons are different sizes
    box-sizing: border-box;
    height: 35px;
    width: 35px;

    position: absolute;
    top: 10px;
    left: 10px;

    background-color: ${({ theme }) => theme.secondaryColors.blank};

    border-radius: ${borderRadius.standard};
    z-index: 100;
    border: 2px solid ${({ theme }) => theme.shades.three};

    .icon {
        flex-grow: 1;
        color: ${({ theme }) => theme.shades.one};
    }
`;
