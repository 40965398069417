import React, { useMemo } from "react";
import { media, styled } from "@iventis/styles";
import { getImageUrl } from "../helpers/asset-helpers";

export const KeyComponent = ({ keyImage, showKey }: { keyImage: string; showKey: boolean }) => {
    const keyImageUrl = useMemo(() => getImageUrl(keyImage), [keyImage]);
    return <KeyImage alt="key" showKey={showKey} src={keyImageUrl} />;
};

const KeyImage = styled.img<{ showKey: boolean }>`
    // When the image is being used on mobile, ensure it takes up the full screen
    height: 100vh;
    width: 100vw;
    display: ${({ showKey }) => (showKey ? "block" : "none")};

    ${media.small} {
        height: 100%;
        width: auto;
    }
`;
